<template>
    <div class="modal" :class="{'is-active': $parent.show_stop_modal}">
        <div class="modal-background" @click.self="$parent.close_stop_modal()"></div>
        <div class="modal-card">
            <section class="modal-card-body custom_scrollbar-1 p-0">
                <header class="modal-card-head">
                    <p class="modal-card-title">Modal title</p>
                    <button class="delete" @click.prevent="$parent.close_stop_modal()"></button>
                </header>
            </section>
            <section class="modal-card-body p-0">
                <!------------------------------------------ CREATING A GATE ------------------------------------->
                <div v-if="$parent.is_created">
                    <div class="columns m-0 px-2" style="border-bottom: solid 1px #dedede;">
                        <!-------------------------SELECTING ACTION TYPE------------------------------------------------------->
                        <div class="column is-4 ">
                            <label for="">Action type</label>
                            <div class="select is-fullwidth">
                                <select v-model="new_stop.action_type" name="" id="">
                                    <option value="-1">Select </option>
                                    <option value="0">Delivery</option>
                                    <option value="1">Pickup</option>
                                </select>
                            </div>
                        </div>
                        <!----------------------------------SELECTING THE PROJECT----------------------------------------------->
                        <div class="column is-4 stop-options-section">
                            <h4 class="has-text-centered mb-1">Project</h4>
                            <div class="pl-6 is-flex is-flex-direction-column ">
                                <label class="radio ml-2 mb-1 " for="same_project">
                                    <input class="" type="radio" id="same_project" value="0" v-model="selected_project">
                                        Current
                                </label>
                                <label class="radio ml-2" for="another_project">
                                    <input  type="radio" id="another_project" value="1" v-model="selected_project">
                                        Other
                                </label>
                            </div>
                        </div>
                        <!-------------------------SELECTING LOCATION TYPE----------------------------------------------------->
                        <div class="column is-4 ">
                            <h4 class="has-text-centered mb-1">Location</h4>
                            <div class="pl-6 is-flex is-flex-direction-column">
                                <label class="radio ml-2 mb-1" for="gate">
                                    <input  type="radio" id="gate" :value="'sublocation'" v-model="new_stop.entity">
                                        Gate
                                </label>
                                <label class="radio ml-2" for="warehouse">
                                    <input  type="radio" id="warehouse" :value="'warehouse'" v-model="new_stop.entity">
                                        Warehouse
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="columns m-0">
                        <div class="column">
                             <div v-if="new_stop.entity == 'sublocation'">
                                <!-------------GATE--------------------->
                                <label class="mr-2" for="">Stop location (Gate)</label>
                                <div class="select">
                                    <select v-model="new_stop.action_stop_id" name="" id="">
                                        <option value="0">Select gate</option>
                                        <option v-for="(gate, index) in $parent.$parent.warehouses_parameters
                                        .filter(t => t.type_id == 3 && t.id != $parent.stop_modal_truck_job.details.destination)" :key="index"
                                        :value="gate.id">{{gate.value}}</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="new_stop.entity == 'warehouse'">
                                <!-------------Warehouse--------------------->
                                <div class="is-flex is-align-items-center"> 
                                    <label class="mr-2" for="">Warehouse</label>
                                </div>
                                <div class="select">
                                    <select v-model="new_stop.action_stop_id" @change="selected_warehouse_jobs">
                                        <option value="0">Select warehouse</option>
                                        <option v-for="(warehouse, index) in Object.keys(warehouses)" :key="index" 
                                            :value="warehouse">{{warehouses[warehouse].Caption}}</option>
                                    </select>
                                </div>

                                <div class="column">
                                    <table>
                                        <th>Caption</th>
                                        <th class="pl-4 pr-4">Qty</th>
                                        <th>Amount</th>
                                        <tr v-for="(material, index) in materials_list" :key="index">
                                            <td>{{material.Caption}}</td>
                                            <td class="pl-4">{{material.Qty}}</td>
                                            <td class="ml-4 field has-addons mb-1 add-materials-table-input" width="85px">
                                                <input class="input is-small has-text-centered" v-model="material.Amount"
													style="height: inherit; " type="number" :placeholder=material.Qty>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <!-- <pre>{{new_stop}}</pre> -->
                        </div>
                    </div>
                </div>

                <div v-else>
<!------------------------------------------Editing a gate------------------------------------->
                    <!-- {{$parent.stop_modal_data}} -->
                    <div class="columns">
                        <div class="column is-3">
                            <label for="">Stop location (Gate)</label>
                            <div class="select">
                                <select v-model="stop_modal_data.details.destination" >
                                    <option value="0">Edit gate</option>
                                    <option v-for="(gate, index) in $parent.$parent.warehouses_parameters
                                    .filter(t => t.type_id == 3 && t.id != $parent.stop_modal_truck_job.details.destination)" :key="index"
                                    :value="gate.id">{{gate.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="column is-3">
                            <label for="">Action type</label>
                            <div class="select">
                                <select disabled name="" id="">
                                    <option value="0">Select </option>
                                    
                                </select>
                            </div>
                        </div>

<!----------------------------------------------------------------PICK LOCATION-------------------------------------------------------------->
                       
                            <div class="column ml-auto has-text-centered">
                                <h4 class="has-text-centered">Transport type</h4>
                                <div class="columns">
                                    <div class="column">
                                    <label class="radio" for="location_1">
                                    <input type="radio" id="location_1" value="1" v-model="selected_project">
                                        Location 1
                                    </label>
                                    </div>
                                    <div class="column">
                                        <label class="radio ml-2" for="location_2">
                                        <input  type="radio" id="location_2" value="2" v-model="selected_project">
                                            Location 2
                                        </label>
                                    </div>
                                </div>
                                <div v-if="selected_project == 1" class="columns" style="justify-content:center;">
                                    <div class="select">
                                        <select disabled name="" id="">
                                            <option value="0">Select ...</option> 
                                        </select>
                                    </div>
                                </div>
                                <div v-if="selected_project == 2" class="columns" style="justify-content:center;">
                                    <div class="select">
                                        <select disabled name="" id="">
                                            <option value="0">Select ...</option> 
                                        </select>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                <!-- <pre>{{$parent.$parent.selected_stop_jobs_materials}}</pre>
                <pre>{{materials_list}}</pre> -->
            <!-- Content ... -->
            </section>
            <footer class="modal-card-foot">
                <div v-if="$parent.is_created" class="ml-auto">
                    <a  
                        @click.prevent="$parent.addNewJob($parent.stop_modal_job, $parent.stop_modal_truck_job.guid, $parent.stop_modal_truck_job, 
                            {...new_stop, 'materials_list': materials_list})" 
                        href="" class="button is-success is-small">Create</a>
                </div>
                <div v-else class="ml-auto">
                    <a @click.prevent="$parent.save_truck_job_details(
                        {
                            ...stop_modal_data.details, 
                            'materials_list': materials_list
                        })" href="" class="button is-warning is-small">Save Changes</a>
                    <a @click.prevent="$parent.delete_truck_stop(stop_modal_data, $parent.stop_modal_truck_job, $parent.selected_project_gate_index)" href="" class="button is-danger is-small">Delete Gate</a>
                </div>
            <!-- <button class="button is-success">Save changes</button>
            <button class="button">Cancel</button> -->
            </footer>
        </div>

    </div>
</template>

<script>
export default {
    data(){
		return {
            new_stop: {
                action_stop_id: 0,
                action_type: 0,
                entity: 'sublocation'
            },
            stop_modal_data: {},
            materials_list: [],
            selected_project: 0
        }
    },
    computed: {
        isDisabled(){
            var a = false 
            this.new_stop.gate_id == 0 ? a = true : a = false
            return a
        },
        warehouses(){
            var l = {} 
            this.$parent.$parent.$parent.jobs_warehouses.forEach(j => {
                if(j.Stock.ID != this.$parent.$parent.selected_warehouse_key && !l[j.Stock.ID]){
                    l[j.Stock.ID] = {} 
                    l[j.Stock.ID] = j.Stock
                }
                
            })
            return l
        },
        
        
    },
    methods: {
        getMaterialList(){
            var list = []
            this.$parent.$parent.selected_stop_jobs_materials.map(j =>{
                j.materials.map(m =>{
                    // console.log('m', m)
                    if(this.$parent.$parent.direction == true){
                        if(m.Remains > 0){
                            list.push({
                            "IdJob": j.IdJob,
                            "Caption": m.Caption,
                            "IdST": m.IdST,
                            "Qty": this.getQty(m),
                            "Group": m.Group,
                            "DayTimeIn": j.DayTimeIn,
                            "DayTimeOut": j.DayTimeOut,
                            "Number": m.Number,
                            "Amount": 0
                            })
                        }
                    } else{
                        console.log('materials', m)
                        if(m.RemainsToReturn > 0){
                            list.push({
                            "IdJob": j.IdJob,
                            "Caption": m.Caption,
                            "IdST": m.IdST,
                            "Qty": this.getQty(m),
                            "Group": m.Group,
                            "DayTimeIn": j.DayTimeIn,
                            "DayTimeOut": j.DayTimeOut,
                            "Number": m.Number,
                            "Amount": 0
                            })
                        }
                    }
                })
            })
            this.materials_list = list
            return list
        },
        selected_warehouse_jobs() {
            this.$parent.$parent.selected_stop_jobs_materials = this.new_stop.entity == 'warehouse' ? [...this.$parent.$parent.$parent.jobs
					.filter(e => e.Stock.ID == this.new_stop.action_stop_id 
						&& !e.Caption.toLowerCase().includes('- request') // exclude the request job 
						&& e.Caption.toLowerCase().includes('- w:') // allow only the warehouse job
					)
            ].sort(this.$parent.$parent.$parent.sortDates) : []
            if(this.$parent.$parent.selected_stop_jobs_materials.length) 
                this.$parent.$parent.revaluateSelectedWarehouseJobs('selected_stop_jobs_materials')
            this.getMaterialList()
        },
        getQty(m){
            var a = 0
            if(this.$parent.$parent.direction == true){
                if(m.PlannedAmount == 0 && m.LoadedAmount ==0)
                    a = m.Remains    
                else if(m.PlannedAmount > 0)
                    a = m.QtyWas - m.PlannedAmount
            } 
            else{
                if(m.PlannedAmountReturn == 0)
                    a = m.Remains   
                else if(m.PlannedAmountReturn > 0)
                    a = m.Remains - m.PlannedAmountReturn
            }
            return a
        }
    },
    created() {
        this.stop_modal_data = JSON.parse(JSON.stringify(this.$parent.stop_modal_data))
        // this.new_stop.action_stop_id = this.stop_modal_data.origin ? this.stop_modal_data.origin : this.stop_modal_data.destination
        if(this.new_stop.entity == 'sublocation') this.$parent.$parent.selected_stop_jobs_materials = []
        this.materials_list = this.getMaterialList
    }

}
</script>

<style>

    .stop-options-section{
        border: solid 1px;
        border-width: 0px 1px;
        border-color: #dedede;
    }

</style>
